import React, { useEffect, useState } from 'react';
import { getTrendingNames } from '../lib/trends';
import { TrendingNames } from './TrendingNames';
import type { TrendingName } from '../lib/trends';

export function Inspiration() {
  const [trendingNames, setTrendingNames] = useState<Record<string, TrendingName[]>>({});
  const [isLoadingTrends, setIsLoadingTrends] = useState(true);
  const [trendsError, setTrendsError] = useState<string | null>(null);

  useEffect(() => {
    async function loadData() {
      try {
        setIsLoadingTrends(true);
        const trends = await getTrendingNames();
        setTrendingNames(trends);
        setTrendsError(null);
      } catch (error) {
        setTrendsError('Trends konnten nicht geladen werden');
      } finally {
        setIsLoadingTrends(false);
      }
    }

    loadData();
    // Aktualisiere die Daten alle 24 Stunden
    const interval = setInterval(loadData, 24 * 60 * 60 * 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <section id="inspiration" className="py-16 px-4 bg-gradient-to-b from-white to-blue-50">
      <div className="max-w-7xl mx-auto">
        <h2 className="text-3xl font-semibold text-center text-gray-800 mb-12">
          Inspiration & Trends
        </h2>

        <TrendingNames
          trendingNames={trendingNames}
          isLoading={isLoadingTrends}
          error={trendsError}
        />
      </div>
    </section>
  );
}