import React, { useState, useEffect } from 'react';
import { Header } from './components/Header';
import { Hero } from './components/Hero';
import { NameGenerator } from './components/NameGenerator';
import { Features } from './components/Features';
import { Inspiration } from './components/Inspiration';
import { FavoritesList } from './components/FavoritesList';
import { Footer } from './components/Footer';
import { AuthModal } from './components/AuthModal';
import { EmailVerificationBanner } from './components/EmailVerificationBanner';
import { AuthProvider } from './contexts/AuthContext';
import { Toaster } from 'react-hot-toast';

export function App() {
  const [isAuthModalOpen, setIsAuthModalOpen] = useState(false);

  useEffect(() => {
    const handleOpenAuthModal = () => setIsAuthModalOpen(true);
    window.addEventListener('openAuthModal', handleOpenAuthModal);
    return () => window.removeEventListener('openAuthModal', handleOpenAuthModal);
  }, []);

  return (
    <AuthProvider>
      <div className="min-h-screen bg-white">
        <Toaster position="top-center" />
        <Header />
        <EmailVerificationBanner />
        <main>
          <Hero />
          <NameGenerator />
          <section id="favorites" className="py-16 px-4 bg-gray-50">
            <div className="max-w-7xl mx-auto">
              <h2 className="text-3xl font-semibold text-center text-gray-800 mb-8">
                Meine Favoriten
              </h2>
              <FavoritesList />
            </div>
          </section>
          <Features />
          <Inspiration />
        </main>
        <Footer />
        <AuthModal
          isOpen={isAuthModalOpen}
          onClose={() => setIsAuthModalOpen(false)}
        />
      </div>
    </AuthProvider>
  );
}