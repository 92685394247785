import React from 'react';
import { Brain, Heart, Globe, Sparkles } from 'lucide-react';

export function Features() {
  const features = [
    {
      icon: Brain,
      title: 'KI-gestützte Vorschläge',
      description: 'Unsere KI lernt von deinen Präferenzen und schlägt passende Namen vor.',
    },
    {
      icon: Heart,
      title: 'Persönliche Favoriten',
      description: 'Speichere deine Lieblingsvorschläge und vergleiche sie später.',
    },
    {
      icon: Globe,
      title: 'Internationale Namen',
      description: 'Entdecke Namen aus verschiedenen Kulturen und deren Bedeutungen.',
    },
    {
      icon: Sparkles,
      title: 'Moderne Trends',
      description: 'Bleibe auf dem Laufenden mit aktuellen Namens-Trends.',
    },
  ];

  return (
    <section className="py-16 px-4 bg-gray-50">
      <div className="max-w-7xl mx-auto">
        <h2 className="text-3xl font-semibold text-center text-gray-800 mb-12">
          Warum unser KI Babynamen Finder?
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {features.map((feature) => (
            <div
              key={feature.title}
              className="bg-white p-6 rounded-xl shadow-sm hover:shadow-md transition-shadow"
            >
              <feature.icon className="w-10 h-10 text-pink-500 mb-4" />
              <h3 className="text-xl font-semibold text-gray-800 mb-2">{feature.title}</h3>
              <p className="text-gray-600">{feature.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}