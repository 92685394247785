import { 
  collection, 
  doc, 
  setDoc, 
  deleteDoc, 
  getDocs, 
  query, 
  where,
  serverTimestamp
} from 'firebase/firestore';
import { db } from './config';
import type { BabyName } from '../../types';

const FAVORITES_COLLECTION = 'favorites';

export async function addFavorite(userId: string, name: BabyName): Promise<string> {
  if (!userId) {
    throw new Error('Bitte melde dich an, um Favoriten zu speichern');
  }

  try {
    const favoriteRef = doc(collection(db, FAVORITES_COLLECTION));
    const favoriteData = {
      userId,
      name: name.name,
      origin: name.origin,
      meaning: name.meaning,
      gender: name.gender || 'unisex',
      createdAt: serverTimestamp()
    };

    await setDoc(favoriteRef, favoriteData);
    return favoriteRef.id;
  } catch (error) {
    console.error('Error adding favorite:', error);
    throw new Error('Fehler beim Speichern des Favoriten');
  }
}

export async function removeFavorite(favoriteId: string): Promise<void> {
  try {
    await deleteDoc(doc(db, FAVORITES_COLLECTION, favoriteId));
  } catch (error) {
    console.error('Error removing favorite:', error);
    throw new Error('Fehler beim Entfernen des Favoriten');
  }
}

export async function getFavorites(userId: string): Promise<Array<BabyName & { id: string }>> {
  if (!userId) return [];

  try {
    const q = query(
      collection(db, FAVORITES_COLLECTION),
      where('userId', '==', userId)
    );
    
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map(doc => ({
      id: doc.id,
      name: doc.data().name,
      gender: doc.data().gender || 'unisex',
      origin: doc.data().origin,
      meaning: doc.data().meaning
    }));
  } catch (error) {
    console.error('Error getting favorites:', error);
    return [];
  }
}