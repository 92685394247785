import { auth } from './firebase/config';
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut as firebaseSignOut,
  sendPasswordResetEmail,
  sendEmailVerification,
  type User
} from 'firebase/auth';

export async function signUp(email: string, password: string): Promise<User> {
  try {
    const result = await createUserWithEmailAndPassword(auth, email, password);
    await sendEmailVerification(result.user);
    return result.user;
  } catch (error: any) {
    if (error.code === 'auth/email-already-in-use') {
      throw new Error('Diese E-Mail-Adresse wird bereits verwendet');
    } else if (error.code === 'auth/weak-password') {
      throw new Error('Das Passwort muss mindestens 6 Zeichen lang sein');
    }
    throw new Error('Ein Fehler ist bei der Registrierung aufgetreten');
  }
}

export async function signIn(email: string, password: string): Promise<User> {
  try {
    const result = await signInWithEmailAndPassword(auth, email, password);
    if (!result.user.emailVerified) {
      throw new Error('Bitte bestätige zuerst deine E-Mail-Adresse');
    }
    return result.user;
  } catch (error: any) {
    if (error.code === 'auth/user-not-found' || error.code === 'auth/wrong-password') {
      throw new Error('E-Mail oder Passwort ist falsch');
    }
    throw error;
  }
}

export async function signOut(): Promise<void> {
  try {
    await firebaseSignOut(auth);
  } catch (error) {
    throw new Error('Ein Fehler ist beim Abmelden aufgetreten');
  }
}

export async function resetPassword(email: string): Promise<void> {
  try {
    await sendPasswordResetEmail(auth, email);
  } catch (error: any) {
    if (error.code === 'auth/user-not-found') {
      throw new Error('Es wurde kein Konto mit dieser E-Mail-Adresse gefunden');
    }
    throw new Error('Ein Fehler ist beim Zurücksetzen des Passworts aufgetreten');
  }
}

export async function resendVerificationEmail(user: User): Promise<void> {
  try {
    await sendEmailVerification(user);
  } catch (error) {
    throw new Error('Fehler beim Senden der Bestätigungs-E-Mail');
  }
}