import React from 'react';
import { Heart, Loader } from 'lucide-react';
import { useAuth } from '../hooks/useAuth';
import { useFavorites } from '../hooks/useFavorites';
import { removeFavorite } from '../lib/firebase/favorites';
import { ProtectedFeature } from './ProtectedFeature';
import toast from 'react-hot-toast';

export function FavoritesList() {
  const { user } = useAuth();
  const { favorites, isLoading, error } = useFavorites();

  const handleRemoveFavorite = async (favoriteId: string) => {
    try {
      await removeFavorite(favoriteId);
      toast.success('Favorit entfernt');
    } catch (error) {
      toast.error('Fehler beim Entfernen des Favoriten');
    }
  };

  return (
    <ProtectedFeature fallbackMessage="Bitte bestätige deine E-Mail-Adresse, um deine Favoriten zu sehen.">
      {isLoading ? (
        <div className="flex justify-center py-8">
          <Loader className="w-8 h-8 text-pink-500 animate-spin" />
        </div>
      ) : error ? (
        <div className="text-center py-8 text-red-600">
          <p>{error}</p>
        </div>
      ) : favorites.length === 0 ? (
        <div className="text-center py-8">
          <p className="text-gray-600">
            Du hast noch keine Namen als Favoriten gespeichert.
          </p>
        </div>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {favorites.map((favorite) => (
            <div
              key={favorite.id}
              className="bg-white p-6 rounded-lg shadow-sm hover:shadow-md transition-shadow border border-gray-100"
            >
              <div className="flex justify-between items-start">
                <div>
                  <h4 className="text-xl font-semibold text-gray-800">{favorite.name}</h4>
                  <p className="text-sm text-gray-600 mt-1">Herkunft: {favorite.origin}</p>
                  <p className="text-sm text-gray-500 mt-1">{favorite.meaning}</p>
                </div>
                <button
                  onClick={() => handleRemoveFavorite(favorite.id)}
                  className="text-pink-500 hover:text-pink-600 transition-colors"
                  title="Von Favoriten entfernen"
                >
                  <Heart className="w-6 h-6 fill-current" />
                </button>
              </div>
            </div>
          ))}
        </div>
      )}
    </ProtectedFeature>
  );
}