import React, { useState } from 'react';
import { X } from 'lucide-react';
import { useAuth } from '../contexts/AuthContext';
import { resetPassword } from '../lib/auth';
import { PasswordValidation, validatePassword } from './PasswordValidation';
import toast from 'react-hot-toast';

interface AuthModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export function AuthModal({ isOpen, onClose }: AuthModalProps) {
  const [isSignUp, setIsSignUp] = useState(false);
  const [isResetPassword, setIsResetPassword] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { signUp, signIn } = useAuth();

  if (!isOpen) return null;

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    try {
      if (isResetPassword) {
        await resetPassword(email);
        toast.success('E-Mail zum Zurücksetzen des Passworts wurde versendet');
        setIsResetPassword(false);
      } else if (isSignUp) {
        if (!validatePassword(password)) {
          toast.error('Bitte erfülle alle Passwort-Anforderungen');
          return;
        }
        await signUp(email, password);
        toast.success('Konto erfolgreich erstellt! Bitte bestätige deine E-Mail-Adresse.');
        onClose();
      } else {
        await signIn(email, password);
        toast.success('Erfolgreich angemeldet!');
        onClose();
      }
    } catch (error) {
      toast.error(error instanceof Error ? error.message : 'Ein Fehler ist aufgetreten');
    }
  };

  const resetForm = () => {
    setEmail('');
    setPassword('');
    setIsResetPassword(false);
    setIsSignUp(false);
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-8 max-w-md w-full relative">
        <button
          onClick={() => {
            resetForm();
            onClose();
          }}
          className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
        >
          <X className="w-6 h-6" />
        </button>
        
        <h2 className="text-2xl font-semibold mb-6">
          {isResetPassword 
            ? 'Passwort zurücksetzen'
            : isSignUp 
              ? 'Konto erstellen' 
              : 'Anmelden'}
        </h2>
        
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              E-Mail
            </label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full rounded-lg border-gray-300 shadow-sm focus:border-pink-500 focus:ring-pink-500"
              required
            />
          </div>
          
          {!isResetPassword && (
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Passwort
              </label>
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="w-full rounded-lg border-gray-300 shadow-sm focus:border-pink-500 focus:ring-pink-500"
                required
              />
              {isSignUp && <PasswordValidation password={password} />}
            </div>
          )}
          
          <button
            type="submit"
            className="w-full bg-gradient-to-r from-blue-500 to-pink-500 text-white py-2 rounded-lg hover:opacity-90 transition-opacity"
          >
            {isResetPassword 
              ? 'Zurücksetzen-Link senden'
              : isSignUp 
                ? 'Registrieren' 
                : 'Anmelden'}
          </button>
        </form>
        
        <div className="mt-4 text-center text-sm text-gray-600">
          {isResetPassword ? (
            <button
              onClick={() => setIsResetPassword(false)}
              className="text-pink-500 hover:text-pink-600"
            >
              Zurück zur Anmeldung
            </button>
          ) : (
            <>
              {isSignUp ? 'Bereits ein Konto?' : 'Noch kein Konto?'}
              <button
                onClick={() => setIsSignUp(!isSignUp)}
                className="ml-1 text-pink-500 hover:text-pink-600"
              >
                {isSignUp ? 'Anmelden' : 'Registrieren'}
              </button>
              {!isSignUp && (
                <>
                  <br />
                  <button
                    onClick={() => setIsResetPassword(true)}
                    className="mt-2 text-pink-500 hover:text-pink-600"
                  >
                    Passwort vergessen?
                  </button>
                </>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}