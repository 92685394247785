export const config = {
  api: {
    openai: {
      baseUrl: 'https://api.openai.com/v1',
      apiKey: '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'
    },
    firebase: {
      apiKey: "AIzaSyCgTcBtmaBSqNFGDGvlQ7wOLPIAGx1w10w",
      authDomain: "babynamen-6d126.firebaseapp.com",
      projectId: "babynamen-6d126",
      storageBucket: "babynamen-6d126.firebasestorage.app",
      messagingSenderId: "344477880925",
      appId: "1:344477880925:web:dbf7ef39195d71b9cbb3b4",
      measurementId: "G-RD1VVFSHQE"
    },
    images: {
      hero: 'https://images.unsplash.com/photo-1519689680058-324335c77eba?auto=format&fit=crop&q=80'
    }
  }
} as const;