import React from 'react';
import { TrendingUp } from 'lucide-react';
import type { TrendingName } from '../lib/trends';

interface TrendingNamesProps {
  trendingNames: Record<string, TrendingName[]>;
  isLoading: boolean;
  error: string | null;
}

export function TrendingNames({ trendingNames, isLoading, error }: TrendingNamesProps) {
  if (isLoading) {
    return (
      <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
        {[1, 2, 3].map((i) => (
          <div key={i} className="bg-white rounded-xl shadow-sm p-6 animate-pulse">
            <div className="h-6 bg-gray-200 rounded w-1/2 mb-4"></div>
            <div className="space-y-4">
              {[1, 2, 3].map((j) => (
                <div key={j} className="h-24 bg-gray-100 rounded-lg"></div>
              ))}
            </div>
          </div>
        ))}
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-center p-8 bg-red-50 rounded-lg">
        <p className="text-red-600">{error}</p>
      </div>
    );
  }

  return (
    <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
      {Object.entries(trendingNames).map(([category, names]) => (
        <div key={category} className="bg-white rounded-xl shadow-sm p-6">
          <div className="flex items-center gap-2 mb-4">
            <TrendingUp className="w-5 h-5 text-pink-500" />
            <h3 className="text-xl font-semibold text-gray-800">{category}</h3>
          </div>
          <div className="space-y-4">
            {names.map((name) => (
              <div key={name.name} className="flex justify-between items-start p-3 bg-gray-50 rounded-lg">
                <div>
                  <p className="font-medium text-gray-800">{name.name}</p>
                  <p className="text-sm text-gray-600">{name.origin}</p>
                  <p className="text-sm text-gray-500 mt-1">{name.meaning}</p>
                </div>
                <span className="text-green-500 font-medium">{name.trend}</span>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
}