import React from 'react';
import { useAuth } from '../hooks/useAuth';
import { Lock } from 'lucide-react';

interface ProtectedFeatureProps {
  children: React.ReactNode;
  fallbackMessage?: string;
}

export function ProtectedFeature({ children, fallbackMessage }: ProtectedFeatureProps) {
  const { user } = useAuth();

  if (!user) {
    return (
      <div className="text-center py-8 px-4">
        <Lock className="w-12 h-12 text-gray-400 mx-auto mb-4" />
        <p className="text-gray-600">
          Bitte melde dich an, um diese Funktion zu nutzen.
        </p>
      </div>
    );
  }

  if (!user.emailVerified) {
    return (
      <div className="text-center py-8 px-4">
        <Lock className="w-12 h-12 text-yellow-400 mx-auto mb-4" />
        <p className="text-gray-600">
          {fallbackMessage || 'Bitte bestätige zuerst deine E-Mail-Adresse, um diese Funktion zu nutzen.'}
        </p>
      </div>
    );
  }

  return <>{children}</>;
}