import React from 'react';
import { Check, X } from 'lucide-react';

interface PasswordValidationProps {
  password: string;
}

interface ValidationRule {
  id: string;
  label: string;
  validate: (password: string) => boolean;
}

const validationRules: ValidationRule[] = [
  {
    id: 'length',
    label: 'Mindestens 8 Zeichen',
    validate: (password) => password.length >= 8,
  },
  {
    id: 'uppercase',
    label: 'Mindestens ein Großbuchstabe',
    validate: (password) => /[A-Z]/.test(password),
  },
  {
    id: 'lowercase',
    label: 'Mindestens ein Kleinbuchstabe',
    validate: (password) => /[a-z]/.test(password),
  },
  {
    id: 'number',
    label: 'Mindestens eine Zahl',
    validate: (password) => /[0-9]/.test(password),
  },
];

export function PasswordValidation({ password }: PasswordValidationProps) {
  return (
    <div className="mt-2 space-y-2">
      {validationRules.map((rule) => {
        const isValid = rule.validate(password);
        return (
          <div
            key={rule.id}
            className={`flex items-center gap-2 text-sm ${
              isValid ? 'text-green-600' : 'text-gray-500'
            }`}
          >
            {isValid ? (
              <Check className="w-4 h-4 text-green-500" />
            ) : (
              <X className="w-4 h-4 text-gray-400" />
            )}
            {rule.label}
          </div>
        );
      })}
    </div>
  );
}

export function validatePassword(password: string): boolean {
  return validationRules.every((rule) => rule.validate(password));
}