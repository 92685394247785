import React from 'react';
import { useAuth } from '../contexts/AuthContext';
import { config } from '../config/urls';

export function Hero() {
  const { user } = useAuth();

  const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    if (!user) {
      e.preventDefault();
      window.dispatchEvent(new CustomEvent('openAuthModal'));
    }
  };

  return (
    <section className="relative bg-gradient-to-b from-blue-50 to-transparent py-20 px-4">
      <div className="max-w-4xl mx-auto text-center">
        <h1 className="text-4xl md:text-5xl font-bold text-gray-800 mb-6">
          Finde den perfekten Namen für dein Baby
        </h1>
        <p className="text-xl text-gray-600">
          Unsere KI hilft dir, den idealen Namen zu finden, der perfekt zu deinem kleinen Wunder passt
        </p>
      </div>
      <div className="absolute inset-0 bg-[url('${config.api.images.hero}')] opacity-5 mix-blend-overlay"></div>
    </section>
  );
}