import React, { useState } from 'react';
import { Baby, LogOut } from 'lucide-react';
import { useAuth } from '../hooks/useAuth';
import { AuthModal } from './AuthModal';
import toast from 'react-hot-toast';

export function Header() {
  const [isAuthModalOpen, setIsAuthModalOpen] = useState(false);
  const { user, logout } = useAuth();

  const handleLogout = async () => {
    try {
      await logout();
      toast.success('Erfolgreich abgemeldet');
    } catch (error) {
      toast.error('Fehler beim Abmelden');
    }
  };

  return (
    <>
      <header className="fixed top-0 left-0 right-0 bg-white shadow-sm z-50">
        <div className="bg-gradient-to-r from-blue-50 to-pink-50">
          <div className="max-w-7xl mx-auto flex items-center justify-between py-4 px-4">
            <div className="flex items-center gap-2">
              <Baby className="w-8 h-8 text-pink-500" />
              <h1 className="text-2xl font-semibold bg-gradient-to-r from-blue-600 to-pink-500 bg-clip-text text-transparent">
                KI Babynamen Finder
              </h1>
            </div>
            <nav className="flex items-center gap-8">
              <ul className="hidden md:flex gap-6">
                <li>
                  <a href="#generator" className="text-gray-600 hover:text-pink-500 transition-colors">
                    Name Generator
                  </a>
                </li>
                <li>
                  <a href="#favorites" className="text-gray-600 hover:text-pink-500 transition-colors">
                    Favoriten
                  </a>
                </li>
                <li>
                  <a href="#inspiration" className="text-gray-600 hover:text-pink-500 transition-colors">
                    Inspiration
                  </a>
                </li>
              </ul>
              {user ? (
                <div className="flex items-center gap-4">
                  <span className="text-gray-600 hidden md:inline">
                    {user.email}
                  </span>
                  <button
                    onClick={handleLogout}
                    className="flex items-center gap-2 text-gray-600 hover:text-pink-500 transition-colors"
                  >
                    <LogOut className="w-5 h-5" />
                    <span className="hidden md:inline">Abmelden</span>
                  </button>
                </div>
              ) : (
                <button
                  onClick={() => setIsAuthModalOpen(true)}
                  className="bg-gradient-to-r from-blue-500 to-pink-500 text-white px-6 py-2 rounded-full hover:opacity-90 transition-opacity"
                >
                  Anmelden
                </button>
              )}
            </nav>
          </div>
        </div>
      </header>
      <div className="h-[72px]"></div> {/* Spacer to prevent content from hiding under fixed header */}
      <AuthModal
        isOpen={isAuthModalOpen}
        onClose={() => setIsAuthModalOpen(false)}
      />
    </>
  );
}