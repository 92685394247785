import React from 'react';
import { AlertTriangle } from 'lucide-react';
import { useAuth } from '../hooks/useAuth';
import { resendVerificationEmail } from '../lib/auth';
import toast from 'react-hot-toast';

export function EmailVerificationBanner() {
  const { user } = useAuth();

  if (!user || user.emailVerified) return null;

  const handleResendVerification = async () => {
    try {
      await resendVerificationEmail(user);
      toast.success('Bestätigungs-E-Mail wurde erneut gesendet');
    } catch (error) {
      toast.error('Fehler beim Senden der Bestätigungs-E-Mail');
    }
  };

  return (
    <div className="bg-yellow-50 border-b border-yellow-100">
      <div className="max-w-7xl mx-auto py-3 px-4">
        <div className="flex items-center justify-between flex-wrap gap-2">
          <div className="flex items-center gap-2">
            <AlertTriangle className="w-5 h-5 text-yellow-500" />
            <p className="text-sm text-yellow-700">
              Bitte bestätige deine E-Mail-Adresse, um alle Funktionen nutzen zu können.
            </p>
          </div>
          <button
            onClick={handleResendVerification}
            className="text-sm text-yellow-600 hover:text-yellow-500 font-medium"
          >
            Bestätigungs-E-Mail erneut senden
          </button>
        </div>
      </div>
    </div>
  );
}