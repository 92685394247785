import OpenAI from 'openai';
import type { NamePreferences } from '../../types';
import { config } from '../../config/urls';

const openai = new OpenAI({
  apiKey: config.api.openai.apiKey,
  dangerouslyAllowBrowser: true
});

export interface GeneratedName {
  name: string;
  origin: string;
  meaning: string;
}

export async function generateNames(preferences: NamePreferences): Promise<GeneratedName[]> {
  const prompt = createNameGenerationPrompt(preferences);

  try {
    const response = await openai.chat.completions.create({
      model: "gpt-3.5-turbo",
      messages: [
        {
          role: "system",
          content: "Du bist ein präziser Assistent für Babynamen. Du antwortest ausschließlich mit einem validen JSON-Objekt, das exakt 5 Namen enthält. Alle Beschreibungen sind auf Deutsch."
        },
        {
          role: "user",
          content: prompt
        }
      ],
      response_format: { type: "json_object" },
      temperature: 0.7,
    });

    const content = response.choices[0].message.content;
    if (!content) {
      throw new Error('Keine Antwort vom OpenAI Server erhalten');
    }

    const parsed = JSON.parse(content);
    if (!parsed.names || !Array.isArray(parsed.names)) {
      throw new Error('Ungültiges Antwortformat von OpenAI');
    }

    return parsed.names;
  } catch (error) {
    console.error('Fehler bei der Namengenerierung:', error);
    throw new Error('Es ist ein Fehler bei der Generierung der Namen aufgetreten. Bitte versuche es erneut.');
  }
}

function createNameGenerationPrompt(preferences: NamePreferences): string {
  const genderMap = {
    male: 'Junge',
    female: 'Mädchen',
    unisex: 'Unisex'
  };

  const lengthMap = {
    short: 'kurz',
    medium: 'mittel',
    long: 'lang'
  };

  let additionalCriteria = '';
  if (preferences.lastName) {
    additionalCriteria += `\n- Der Name soll gut zum Nachnamen "${preferences.lastName}" passen`;
  }
  if (preferences.initialLetter) {
    additionalCriteria += `\n- Der Name soll mit dem Buchstaben "${preferences.initialLetter}" beginnen`;
  }

  return `Generiere genau 5 Babynamen als JSON-Array mit den folgenden Eigenschaften pro Name:
{
  "names": [
    {
      "name": "Beispielname",
      "origin": "Kulturelle Herkunft",
      "meaning": "Bedeutung des Namens"
    }
  ]
}

Verwende diese Präferenzen:
- Geschlecht: ${genderMap[preferences.gender]}
- Kulturelle Herkunft: ${preferences.origin || 'beliebig'}
- Bedeutung bezogen auf: ${preferences.meaning || 'beliebige positive Bedeutung'}
- Namenslänge: ${lengthMap[preferences.nameLength]}${additionalCriteria}

WICHTIG:
- Gib EXAKT 5 Namen zurück
- Alle Beschreibungen MÜSSEN auf Deutsch sein
- Halte das JSON-Format EXAKT ein
- Verwende nur die Felder "name", "origin" und "meaning"`;
}