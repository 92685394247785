import React from 'react';
import { Baby, Heart } from 'lucide-react';

export function Footer() {
  return (
    <footer className="bg-gray-50 border-t border-gray-200 py-12 px-4">
      <div className="max-w-7xl mx-auto">
        <div className="flex flex-col md:flex-row justify-between items-center gap-8">
          <div className="flex items-center gap-2">
            <Baby className="w-6 h-6 text-pink-500" />
            <span className="text-xl font-semibold text-gray-800">KI Babynamen Finder</span>
          </div>
          <div className="flex flex-wrap justify-center gap-8">
            <a href="#" className="text-gray-600 hover:text-pink-500 transition-colors">
              Über uns
            </a>
            <a href="#" className="text-gray-600 hover:text-pink-500 transition-colors">
              Datenschutz
            </a>
            <a href="#" className="text-gray-600 hover:text-pink-500 transition-colors">
              Impressum
            </a>
            <a href="#" className="text-gray-600 hover:text-pink-500 transition-colors">
              Kontakt
            </a>
          </div>
        </div>
        <div className="mt-8 pt-8 border-t border-gray-200 text-center text-gray-600">
          <p className="flex items-center justify-center gap-1">
            Made with <Heart className="w-4 h-4 text-pink-500" /> für werdende Eltern
          </p>
        </div>
      </div>
    </footer>
  );
}