import React, { useState, useEffect } from 'react';
import { Heart, Loader } from 'lucide-react';
import { useAuth } from '../hooks/useAuth';
import { addFavorite, removeFavorite } from '../lib/firebase/favorites';
import { useFavorites } from '../hooks/useFavorites';
import toast from 'react-hot-toast';
import type { BabyName } from '../types';

interface Name {
  name: string;
  origin: string;
  meaning: string;
}

interface NameResultsProps {
  names: Name[];
  isLoading: boolean;
}

export function NameResults({ names, isLoading }: NameResultsProps) {
  const { user } = useAuth();
  const { favorites } = useFavorites();
  const [loadingFavorites, setLoadingFavorites] = useState<Record<string, boolean>>({});

  // Create a map of favorite names for quick lookup
  const favoriteMap = favorites.reduce((acc, fav) => {
    acc[fav.name] = fav.id;
    return acc;
  }, {} as Record<string, string>);

  const toggleFavorite = async (name: Name) => {
    if (!user) {
      window.dispatchEvent(new CustomEvent('openAuthModal'));
      return;
    }

    setLoadingFavorites(prev => ({ ...prev, [name.name]: true }));

    try {
      if (favoriteMap[name.name]) {
        await removeFavorite(favoriteMap[name.name]);
        toast.success('Name von Favoriten entfernt');
      } else {
        const babyName: BabyName = {
          name: name.name,
          gender: 'unisex',
          origin: name.origin,
          meaning: name.meaning
        };
        await addFavorite(user.uid, babyName);
        toast.success('Name zu Favoriten hinzugefügt');
      }
    } catch (error) {
      toast.error(error instanceof Error ? error.message : 'Ein Fehler ist aufgetreten');
    } finally {
      setLoadingFavorites(prev => ({ ...prev, [name.name]: false }));
    }
  };

  if (isLoading) {
    return (
      <div className="mt-8 flex justify-center">
        <Loader className="w-12 h-12 text-pink-500 animate-spin" />
      </div>
    );
  }

  if (!names.length) return null;

  return (
    <div className="mt-8 space-y-4">
      <h3 className="text-2xl font-semibold text-gray-800 mb-6">
        Vorgeschlagene Namen
      </h3>
      <div className="grid gap-4 md:grid-cols-2">
        {names.map((name) => (
          <div
            key={name.name}
            className="bg-white p-6 rounded-lg shadow-sm hover:shadow-md transition-shadow border border-gray-100"
          >
            <div className="flex justify-between items-start">
              <div>
                <h4 className="text-xl font-semibold text-gray-800">{name.name}</h4>
                <p className="text-sm text-gray-600 mt-1">Herkunft: {name.origin}</p>
              </div>
              <button
                onClick={() => toggleFavorite(name)}
                disabled={loadingFavorites[name.name]}
                className={`transition-colors ${
                  loadingFavorites[name.name]
                    ? 'opacity-50 cursor-wait'
                    : favoriteMap[name.name]
                    ? 'text-pink-500 hover:text-pink-600'
                    : 'text-gray-400 hover:text-pink-500'
                }`}
                title={favoriteMap[name.name] ? 'Von Favoriten entfernen' : 'Als Favorit speichern'}
              >
                <Heart
                  className={`w-6 h-6 ${favoriteMap[name.name] ? 'fill-current' : ''}`}
                />
              </button>
            </div>
            <p className="mt-3 text-gray-700">{name.meaning}</p>
          </div>
        ))}
      </div>
    </div>
  );
}