import React, { useState } from 'react';
import { Search } from 'lucide-react';
import { useAuth } from '../hooks/useAuth';
import { NamePreferences, Gender, NameLength } from '../types';
import { generateNames } from '../lib/api/openai';
import { NameResults } from './NameResults';
import { ProtectedFeature } from './ProtectedFeature';

export function NameGenerator() {
  const { user } = useAuth();
  const [preferences, setPreferences] = useState<NamePreferences>({
    gender: 'unisex',
    origin: '',
    meaning: '',
    nameLength: 'medium',
    lastName: '',
    initialLetter: '',
  });
  const [names, setNames] = useState<Array<{ name: string; origin: string; meaning: string }>>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!user?.emailVerified) {
      window.dispatchEvent(new CustomEvent('openAuthModal'));
      return;
    }
    
    setIsLoading(true);
    setError(null);
    try {
      const generatedNames = await generateNames(preferences);
      setNames(generatedNames);
    } catch (error) {
      setError(error instanceof Error ? error.message : 'Ein unerwarteter Fehler ist aufgetreten');
      setNames([]);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <section id="generator" className="py-16 px-4">
      <ProtectedFeature>
        <div className="max-w-3xl mx-auto bg-white rounded-2xl shadow-xl p-8">
          <h2 className="text-3xl font-semibold text-gray-800 mb-6 text-center">
            Personalisiere deine Namenssuche
          </h2>
          <form onSubmit={handleSubmit} className="space-y-6">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Geschlecht
                </label>
                <select
                  value={preferences.gender}
                  onChange={(e) => setPreferences({ ...preferences, gender: e.target.value as Gender })}
                  className="w-full rounded-lg border-gray-300 shadow-sm focus:border-pink-500 focus:ring-pink-500"
                >
                  <option value="male">Junge</option>
                  <option value="female">Mädchen</option>
                  <option value="unisex">Unisex</option>
                </select>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Herkunft
                </label>
                <input
                  type="text"
                  value={preferences.origin}
                  onChange={(e) => setPreferences({ ...preferences, origin: e.target.value })}
                  placeholder="z.B. Deutsch, Englisch"
                  className="w-full rounded-lg border-gray-300 shadow-sm focus:border-pink-500 focus:ring-pink-500"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Bedeutung
                </label>
                <input
                  type="text"
                  value={preferences.meaning}
                  onChange={(e) => setPreferences({ ...preferences, meaning: e.target.value })}
                  placeholder="z.B. Stark, Liebe, Natur"
                  className="w-full rounded-lg border-gray-300 shadow-sm focus:border-pink-500 focus:ring-pink-500"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Namenslänge
                </label>
                <select
                  value={preferences.nameLength}
                  onChange={(e) => setPreferences({ ...preferences, nameLength: e.target.value as NameLength })}
                  className="w-full rounded-lg border-gray-300 shadow-sm focus:border-pink-500 focus:ring-pink-500"
                >
                  <option value="short">Kurz</option>
                  <option value="medium">Mittel</option>
                  <option value="long">Lang</option>
                </select>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Nachname (optional)
                </label>
                <input
                  type="text"
                  value={preferences.lastName}
                  onChange={(e) => setPreferences({ ...preferences, lastName: e.target.value })}
                  placeholder="Für bessere Namenskombinationen"
                  className="w-full rounded-lg border-gray-300 shadow-sm focus:border-pink-500 focus:ring-pink-500"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Anfangsbuchstabe (optional)
                </label>
                <input
                  type="text"
                  value={preferences.initialLetter}
                  onChange={(e) => setPreferences({ ...preferences, initialLetter: e.target.value.slice(0, 1).toUpperCase() })}
                  placeholder="z.B. M"
                  maxLength={1}
                  className="w-full rounded-lg border-gray-300 shadow-sm focus:border-pink-500 focus:ring-pink-500"
                />
              </div>
            </div>
            <button
              type="submit"
              className="w-full flex items-center justify-center gap-2 bg-gradient-to-r from-blue-500 to-pink-500 text-white py-3 rounded-lg hover:opacity-90 transition-opacity"
              disabled={isLoading}
            >
              <Search className="w-5 h-5" />
              {isLoading ? 'Generiere Namen...' : 'Namen generieren'}
            </button>
          </form>
          {error && (
            <div className="mt-4 p-4 bg-red-50 border border-red-200 rounded-lg text-red-600">
              {error}
            </div>
          )}
          <NameResults names={names} isLoading={isLoading} />
        </div>
      </ProtectedFeature>
    </section>
  );
}