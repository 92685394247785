import { useState, useEffect } from 'react';
import { 
  collection, 
  query, 
  where, 
  onSnapshot
} from 'firebase/firestore';
import { db } from '../lib/firebase/config';
import { useAuth } from './useAuth';
import type { BabyName } from '../types';

const FAVORITES_COLLECTION = 'favorites';

export function useFavorites() {
  const { user } = useAuth();
  const [favorites, setFavorites] = useState<Array<BabyName & { id: string }>>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (!user) {
      setFavorites([]);
      setIsLoading(false);
      return;
    }

    setIsLoading(true);
    setError(null);

    // Create query for user's favorites
    const q = query(
      collection(db, FAVORITES_COLLECTION),
      where('userId', '==', user.uid)
    );

    // Set up real-time listener
    const unsubscribe = onSnapshot(q, 
      (snapshot) => {
        const updatedFavorites = snapshot.docs.map(doc => ({
          id: doc.id,
          name: doc.data().name,
          gender: doc.data().gender || 'unisex',
          origin: doc.data().origin,
          meaning: doc.data().meaning
        }));
        setFavorites(updatedFavorites);
        setIsLoading(false);
      },
      (error) => {
        console.error('Error in favorites listener:', error);
        setError('Fehler beim Laden der Favoriten');
        setIsLoading(false);
      }
    );

    // Clean up listener on unmount
    return () => unsubscribe();
  }, [user]);

  return { favorites, isLoading, error };
}