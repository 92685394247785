import OpenAI from 'openai';

const openai = new OpenAI({
  apiKey: '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',
  dangerouslyAllowBrowser: true
});

export interface TrendingName {
  name: string;
  origin: string;
  meaning: string;
  trend: string;
}

export async function getTrendingNames(): Promise<Record<string, TrendingName[]>> {
  const prompt = `Generiere aktuelle Namens-Trends als JSON-Objekt mit drei Kategorien (Deutschland, International, Aufstrebend).
  Jede Kategorie soll genau 3 Namen enthalten. Format:
  {
    "Deutschland": [
      { "name": "Name", "origin": "Herkunft", "meaning": "Bedeutung", "trend": "↑ +X" }
    ],
    "International": [...],
    "Aufstrebend": [...]
  }
  
  WICHTIG:
  - Alle Beschreibungen auf Deutsch
  - Realistische Trend-Werte (+1 bis +12)
  - Aktuelle, moderne Namen
  - Bedeutungen kurz und prägnant`;

  try {
    const response = await openai.chat.completions.create({
      model: "gpt-3.5-turbo",
      messages: [
        {
          role: "system",
          content: "Du bist ein Experte für Babynamen-Trends. Antworte nur mit validem JSON."
        },
        {
          role: "user",
          content: prompt
        }
      ],
      response_format: { type: "json_object" },
      temperature: 0.7,
    });

    const content = response.choices[0].message.content;
    if (!content) {
      throw new Error('Keine Antwort vom OpenAI Server erhalten');
    }

    return JSON.parse(content);
  } catch (error) {
    console.error('Fehler beim Abrufen der Trends:', error);
    throw new Error('Trends konnten nicht geladen werden');
  }
}